import { useState, useEffect, useMemo } from "react";
import {
  INIT_SEARCH_CRITERIA,
  Collection,
  SORT_EXAMPLES,
  SEARCH_INFO,
  UserStatusID,
} from "../../../constants/common";
import { getMongoDb, getAggregateNlc004 } from "../../../utils/query";
import { useRealmApp } from "../../../contexts/RealmApp";
import { convertSearchName, useMemoStatusArr } from "../../../utils/utils";
import usePagination, {
  setTotal,
} from "../../component/pagination/usePagination";
import {
  closeTableLoading,
  openTableLoading,
} from "../../base/useLoadingTable";
import { checkFetchErr } from "../../../contexts/CustomErrorBoundary";

type Nlc004FetchParams = {
  sortExamples: typeof SORT_EXAMPLES.NLC004;
  submittedSearchCriteria: typeof INIT_SEARCH_CRITERIA.NLC004;
};

// NLC004画面のデータ取得処理
const useNlc004Fetch = ({
  sortExamples,
  submittedSearchCriteria,
}: Nlc004FetchParams) => {
  const realmAppContext = useRealmApp();
  const { currentUser } = realmAppContext;

  // paginationコンポーネントの値取得
  const [pagination] = usePagination();
  const { perPage, skip } = pagination;

  const [errorAndResult, setErrorAndResult] = useState<{
    result: PatientNlc004StateType[];
    error: Error | null;
  }>({
    result: [],
    error: null,
  });

  // 検索条件をquery用に整形
  const { id } = submittedSearchCriteria;
  const name = convertSearchName(submittedSearchCriteria.name);
  const tel = submittedSearchCriteria.phoneNum;

  // 検索のチェック状態をquery用に整形
  const memoStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLC004,
    submittedSearchCriteria.reportStatus,
  );

  // ユーザステータスのチェック状態
  const initialUserStatusArr = useMemoStatusArr(
    SEARCH_INFO.NLC035_status,
    submittedSearchCriteria.userStatus,
  );

  // 招待者のアクティブ状態を付与・除去
  const userStatusArr = useMemo(() => {
    let statusArr = initialUserStatusArr;
    if (
      statusArr.includes(UserStatusID.ACTIVE) &&
      !statusArr.includes(UserStatusID.INVITATION)
    ) {
      statusArr = [...statusArr, UserStatusID.INVITATION];
    } else if (
      !statusArr.includes(UserStatusID.ACTIVE) &&
      statusArr.includes(UserStatusID.INVITATION)
    ) {
      statusArr = statusArr.filter(
        (status) => status !== UserStatusID.INVITATION,
      );
    }

    return statusArr;
  }, [initialUserStatusArr]);

  // sortを取得 未選択の場合はIDの昇順
  const currentSort = sortExamples.find(
    (column) => column.sortDirection !== "",
  );
  const {
    colName = "patient_nouknow_info.finished_at",
    sortDirection = "desc",
  } = currentSort || {};

  useEffect(() => {
    const fetchData = async () => {
      openTableLoading();
      try {
        const mongoDbPatient = getMongoDb(currentUser, Collection.USERS);
        // 1ページ分のデータとデータ総数取得条件
        const aggregate = getAggregateNlc004(
          id,
          name,
          tel,
          userStatusArr,
          memoStatusArr,
          skip,
          perPage,
          colName,
          sortDirection,
        );
        // mongoDBのデータ
        const aggregateResult = (await mongoDbPatient.aggregate(
          aggregate,
        )) as AggregateResultType<PatientNlc004StateType>;

        const { result, count } = aggregateResult[0];
        const total = count[0]?.count ?? 0;

        setTotal(total);
        setErrorAndResult({ result, error: null });
      } catch (err) {
        setTotal(0);
        setErrorAndResult({ result: [], error: checkFetchErr(err) });
      } finally {
        closeTableLoading();
      }
    };

    void fetchData();
  }, [
    colName,
    currentUser,
    id,
    memoStatusArr,
    name,
    perPage,
    skip,
    sortDirection,
    tel,
    userStatusArr,
  ]);

  return errorAndResult;
};

export default useNlc004Fetch;
